/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Header from "./header"
import "./layout.scss"

const Layout = ({ children, slim }) => {
	const data = useStaticQuery(graphql`
    query SiteTitleQuery {
		site {
			siteMetadata {
					title
				}
			}
		}
	`)

	return (
		<>
			<Helmet>
				<body className="bg-white dark:bg-gunmetal text-gunmetal dark:text-white" />
			</Helmet>
			{slim ? <></> : <Header siteTitle={data.site.siteMetadata?.title || `Title`} />}
			{children}
		</>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	slim: PropTypes.bool,
}

export default Layout
